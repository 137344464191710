import type { Ref } from 'vue'

import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'

import { PAGE_TYPE_WITH_SPECIFIC_FIELDS } from '../service/content-service.config'

export function useIsSpecificLayout(data: Ref<BasePageContent | null>) {
  if (!data.value) return false

  return PAGE_TYPE_WITH_SPECIFIC_FIELDS.includes(data.value.pageType)
}
