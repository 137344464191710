<template>
  <CMSContext v-if="context" v-bind="context" :cms-page-id="data?.id" />

  <!-- name is a reserved NuxtPage prop https://nuxt.com/docs/api/components/nuxt-page#props -->
  <NuxtPage
    v-if="data && isSpecificLayout"
    v-bind="omit(data, ['name'])"
    data-test="specific-layout"
  />

  <DefaultLayout
    v-if="data && !isSpecificLayout"
    v-bind="data"
    data-test="default-layout"
    :simpleLayout
  />
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import CMSContext from '@backmarket/nuxt-layer-cms/CMSContext.vue'
import { omit } from '@backmarket/utils/object/omit'

import { useCMSHead } from './composables/useCMSHead'
import { useFetchContent } from './composables/useFetchPageContent'
import { useFetchPageContext } from './composables/useFetchPageContext'
import { useHandleFetchError } from './composables/useHandleFetchError'
import { useHandleIncorrectRouteParams } from './composables/useHandleIncorrectRouteParams'
import { useIsSpecificLayout } from './composables/useIsSpecificLayout'
import DefaultLayout from './layout/Default.vue'

withDefaults(defineProps<{ simpleLayout?: boolean }>(), { simpleLayout: false })

const { FF_CMS_CONTEXT } = useRuntimeConfig().public

const { data, error } = await useFetchContent()

useHandleFetchError(error)
useHandleIncorrectRouteParams(data)
useCMSHead(data)
const isSpecificLayout = useIsSpecificLayout(data)

const context = useFetchPageContext({
  showCmsContext: FF_CMS_CONTEXT,
  content: data.value,
})
</script>
